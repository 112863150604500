import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  Configuration,
  CreatePaymentRuleDto,
  ErrorResponse,
  PaymentRule,
  PaymentRuleApi,
  UpdatePaymentRuleDto,
} from '@sr-sdks/api-sdk-axios';
import { AxiosError } from 'axios';
import { configuration } from '../../configuration';
import { RootState } from '../../stateStore';
import { ApiLoadingStateEnum } from '../../utils/api/apiLoadingStateEnum';
import axiosInstance, {
  invalidateCacheRequestConfig,
} from '../../utils/axiosInstance';
import { PaymentRuleSliceState } from './paymentRuleSliceState';

// Function for creating an instance of the UsersApi class
const GetPaymentRuleApi = () => {
  return new PaymentRuleApi(
    new Configuration(),
    configuration.PAYMENTS_API_BASE,
    axiosInstance,
  );
};

const create = createAsyncThunk(
  'paymentRule/create',
  async (payload: CreatePaymentRuleDto, thunkApi) => {
    try {
      const response = await GetPaymentRuleApi().paymentRuleControllerCreate(
        payload,
        invalidateCacheRequestConfig('payment-rule'),
      );

      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        (error as AxiosError).response?.data as ErrorResponse,
      );
    }
  },
);

const findAll = createAsyncThunk(
  'paymentRule/findAll',
  async (
    {
      bankDetailsId,
      workspaceId,
    }: {
      bankDetailsId: string;
      workspaceId: string;
    },
    thunkApi,
  ) => {
    try {
      const result = await GetPaymentRuleApi().paymentRuleControllerFindAll(
        workspaceId,
        bankDetailsId,
      );

      return result.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        (error as AxiosError).response?.data as ErrorResponse,
      );
    }
  },
);

const remove = createAsyncThunk(
  'paymentRule/remove',
  async (
    {
      paymentRuleId,
    }: {
      paymentRuleId: string;
    },
    thunkApi,
  ) => {
    try {
      const result = await GetPaymentRuleApi().paymentRuleControllerRemove(
        paymentRuleId,
        invalidateCacheRequestConfig('payment-rule'),
      );

      return result.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        (error as AxiosError).response?.data as ErrorResponse,
      );
    }
  },
);

const update = createAsyncThunk(
  'paymentRule/update',
  async (
    {
      paymentRuleId,
      paymentRulePayload,
    }: {
      paymentRuleId: string;
      paymentRulePayload: UpdatePaymentRuleDto;
    },
    thunkApi,
  ) => {
    try {
      const result = await GetPaymentRuleApi().paymentRuleControllerUpdate(
        paymentRuleId,
        paymentRulePayload,
        invalidateCacheRequestConfig('payment-rule'),
      );

      return result.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        (error as AxiosError).response?.data as ErrorResponse,
      );
    }
  },
);

const initialPaymentRuleState: PaymentRuleSliceState = {
  isCreating: ApiLoadingStateEnum.idle,
  isLoading: ApiLoadingStateEnum.idle,
  isRemoving: ApiLoadingStateEnum.idle,
  isUpdating: ApiLoadingStateEnum.idle,
  paymentRules: [],
};

const paymentRulesSlice = createSlice({
  extraReducers: (builder) => {
    builder
      // Create
      .addCase(create.fulfilled, (state, action) => {
        state.isCreating = ApiLoadingStateEnum.succeeded;

        state.paymentRule = action.payload;
      })
      .addCase(create.pending, (state) => {
        state.isCreating = ApiLoadingStateEnum.loading;
      })
      .addCase(create.rejected, (state, action) => {
        state.isCreating = ApiLoadingStateEnum.failed;
        state.errorResponse = action.payload as ErrorResponse;
      })
      // Update
      .addCase(update.fulfilled, (state, action) => {
        state.isUpdating = ApiLoadingStateEnum.succeeded;

        state.paymentRule = action.payload;
      })
      .addCase(update.pending, (state) => {
        state.isUpdating = ApiLoadingStateEnum.loading;
      })
      .addCase(update.rejected, (state, action) => {
        state.isUpdating = ApiLoadingStateEnum.failed;
        state.errorResponse = action.payload as ErrorResponse;
      })
      // Remove
      .addCase(remove.fulfilled, (state, action) => {
        state.isRemoving = ApiLoadingStateEnum.succeeded;

        state.paymentRule = action.payload;
      })
      .addCase(remove.pending, (state) => {
        state.isRemoving = ApiLoadingStateEnum.loading;
      })
      .addCase(remove.rejected, (state, action) => {
        state.isRemoving = ApiLoadingStateEnum.failed;
        state.errorResponse = action.payload as ErrorResponse;
      })
      // FindAll
      .addCase(findAll.fulfilled, (state, action) => {
        state.isLoading = ApiLoadingStateEnum.succeeded;

        state.paymentRules = action.payload as PaymentRule[];
      })
      .addCase(findAll.pending, (state) => {
        state.isLoading = ApiLoadingStateEnum.loading;
      })
      .addCase(findAll.rejected, (state, action) => {
        state.isLoading = ApiLoadingStateEnum.failed;
        state.errorResponse = action.payload as ErrorResponse;
      });
  },
  initialState: initialPaymentRuleState,
  name: 'paymentRule',
  reducers: {
    resetCreatingLoadingState: (state) => {
      return {
        ...state,
        // Add more loading state here
        isCreating: ApiLoadingStateEnum.idle,
      };
    },
    resetErrorResponse: (state) => {
      return {
        ...state,
        errorResponse: initialPaymentRuleState.errorResponse,
      };
    },
    resetRemoveLoadingState: (state) => {
      return {
        ...state,
        // Add more loading state here
        isRemoving: ApiLoadingStateEnum.idle,
      };
    },
    resetUpdateLoadingState: (state) => {
      return {
        ...state,
        // Add more loading state here
        isUpdating: ApiLoadingStateEnum.idle,
      };
    },
    setDownloadErrorResponse: (state, action) => {
      return {
        ...state,
        errorResponse: action.payload,
      };
    },
  },
});

const paymentRuleThunk = {
  create,
  findAll,
  remove,
  update,
};

const paymentRuleSelectors = {
  errorResponse: (state: RootState) => state.paymentRules.errorResponse,
  isCreating: (state: RootState) => state.paymentRules.isCreating,
  isLoading: (state: RootState) => state.paymentRules.isLoading,
  isRemoving: (state: RootState) => state.paymentRules.isRemoving,
  isUpdating: (state: RootState) => state.paymentRules.isUpdating,
  paymentRule: (state: RootState) => state.paymentRules.paymentRule,
  paymentRules: (state: RootState) => state.paymentRules.paymentRules,
};

export const paymentRuleService = {
  ...paymentRuleThunk,
  actions: paymentRulesSlice.actions,
  selectors: paymentRuleSelectors,
};

export default paymentRulesSlice.reducer;
